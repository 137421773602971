import useApiService from "../src/services/api.service";
import { useAppContext } from "../src/AppContext/App.context";
import { componentList, languageObj } from "../src/constants/languageList";
import LanguageManagerEndpoints from "../src/containers/LanguageManager/LanguageManager.endpoints";

const componetIdFromName = componentList.reduce(
  (obj, item) => Object.assign(obj, { [item.name]: item.id }),
  {}
);
async function loadJSON(url) {
  const res = await fetch(url);
  try {
    return await res.json();
  } catch (e) {
    return {};
  }
}
const getLatestNameSpaceVersionValue = (componentId) => {
  const languageObj = {
    lang_id: 1,
    version: 0,
  };
  if (localStorage.getItem("CustomData")) {
    const languageData = JSON.parse(
      localStorage.getItem("CustomData")
    ).language;
    if (!languageData) {
      return languageObj;
    } else if (languageData.default_lang) {
      // default_lang_id
      let defaultLangID = languageData.default_lang.default_lang_id;
      if (!defaultLangID && Object.keys(languageData.default_lang).length > 0) {
        defaultLangID =
          languageData.default_lang[Object.keys(languageData.default_lang)[0]][
            "default_lang_id"
          ];
      }
      return {
        lang_id: defaultLangID,
        version: 0,
      };
    } else if (languageData.custom_lang) {
      return (
        languageData.custom_lang[componentId] || {
          lang_id: languageData.custom_lang["default_lang_id"],
          version: 0,
        }
      );
    }
    return languageObj;
  } else {
    return languageObj;
  }
};

const GetLanguageJsonFromAPI = async (lang_id, componentId, callback) => {
  const APIservice = useApiService();
  const [appData] = useAppContext();
  const access_token = appData.loginData.access_token;
  const getLanguageRequestBody = (reqBody, headers) => {
    let reqData = {
      reqBody: reqBody,
      headers: headers,
    };
    const isDefaultLanguage = false;
    if (isDefaultLanguage) {
      return LanguageManagerEndpoints.getDefaultLanguageDetails(reqData);
    } else {
      return LanguageManagerEndpoints.getLanguageDetails(reqData);
    }
  };
  let headers = {
    access_token: access_token,
  };

  // let reqBody = {
  //   platform: 0,
  //   component: componentId,
  // };
  let reqBody = {
    lang_id: lang_id,
    platform: 2,
    component: componentId,
    fetch_data: true,
  };
  const res = await APIservice.get(getLanguageRequestBody(reqBody, headers));

  // return res.data[componentId].data;
  localStorage.setItem(
    "trans_" + componentId,
    JSON.stringify(res.data[componentId])
  );
  callback(null, res.data[componentId]["data"]);
  // return data;
};

const plugin = {
  type: "backend",
  init: function (services, backendOptions, i18nextOptions) {
    console.log(services);
    console.log(backendOptions);
    console.log(i18nextOptions);
    /* use services and options */
  },
  read: function async(language, namespace, callback) {
    console.log(namespace);
    console.log(language);
    const componentId = componetIdFromName[namespace];
    if (!componentId) {
      callback(null, {});
      return;
    }
    let languageVersionAndId = getLatestNameSpaceVersionValue(componentId);
    console.log(languageObj);
    if (!languageVersionAndId.version) {
      loadJSON(
        `/locales/${
          languageObj[languageVersionAndId.lang_id]
        }/${namespace}.json`
      ).then((data) => {
        callback(null, data);
      });
    } else {
      if (localStorage.getItem("trans_" + componentId)) {
        let obj = JSON.parse(localStorage.getItem("trans_" + componentId));
        if (obj.version >= languageVersionAndId.version) {
          console.log(obj.version);
          callback(null, obj.data);
        } else {
          const response = GetLanguageJsonFromAPI(
            languageVersionAndId.lang_id,
            componentId,
            callback
          );
          // callback(null, response);
        }
      } else {
        const response = GetLanguageJsonFromAPI(
          languageVersionAndId.lang_id,
          componentId,
          callback
        );
      }
    }
  },

  // only used in backends acting as cache layer
  save: function (language, namespace, data) {
    // store the translations
  },

  create: function (languages, namespace, key, fallbackValue) {
    /* save the missing translation */
  },
};
export default plugin;
