import React, { useEffect, useState, Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../AppContext/App.context";
import { makeStyles } from "@material-ui/core/styles";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import Grid from "@material-ui/core/Grid";
import CommonEndpoints from "../../services/commonApi.service";
import useApiService from "../../services/api.service";
import userDefaultIcon from "../../assets/images/resources/user.png";
import Input from "../../components/Input/Input";
import { useForm } from "react-hook-form";
import useToast from "../../components/Toast/hooks/useToast";
import Phone from "../../components/phone-picker/phone-picker";
import { ValidationService } from "../../utils/Validation";
import EditProfileEndpoints from "./EditProfile.endpoints";
import useLoader from "../../hooks/useLoader";
import useLocalStorage from "../../utils/localStorage";
import Btn from "../../components/Button/Button";
import headerDropdownService from "../../services/headerDropdown.service";

import "./EditProfile.scss";
import { useHistory } from "react-router";
import { themeColor } from "../../constants/themeColor";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "auto",
    paddingTop: "2rem",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "auto",
  },
  basicinfo: {
    padding: "12px",
    marginTop: "40px",
  },
  item: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "0px !important",
  },
  pageheading: {
    fontSize: "26px",
    fontWeight: 600,
    padding: "13px 20px",
    backgroundColor: `${themeColor.whiteColor}`,
    paddingLeft: "51px",
  },
  root: {
    width: "100%",
  },
  btn: {
    margin: theme.spacing(2),
  },
}));

const EditProfile = (props: any, ref: any) => {
  const { t, i18n } = useTranslation(["Profile", "Common"]);
  const [isShownUploadProfilePictureIcon, showUploadProfilePictureIcon] =
    useState(false);
  const fileUpload: any = React.createRef();
  const [image, setImage]: any = useState(userDefaultIcon);
  const ApiService = useApiService();
  const [isLoading, setIsLoading] = React.useState(true);
  const [appData]: any = useAppContext();
  const { register, handleSubmit, errors, getValues, reset } = useForm({
    mode: "onChange",
  });
  const history: any = useHistory();

  const referenceId = useLocalStorage.getItem("referenceId");
  const access_token = appData.loginData.access_token;
  const classes = useStyles();
  const toaster = useToast();
  const dialCode: any = useRef<any>();
  const countryCode: any = useRef();
  const loader: any = useLoader();

  const [preSavedCountryCode, updatePreSavedCountryCode]: any = useState();

  const localStorage = useLocalStorage;

  const details = appData.loginData;

  useEffect(() => {
    setPrefillProfile(details);
  }, []);

  const setPrefillProfile = (details: any) => {
    if (details) {
      reset({
        name: details.name,
        phone: details.phone_number,
        email: details.email,
        //image:  res[0].user_image,
      });
      updatePreSavedCountryCode(
        details.country_code
          ? details.country_code.substring(1)
          : preSavedCountryCode
      );
      if (details.user_image) {
        setImage(details.user_image);
      } else {
        setImage(userDefaultIcon);
      }
    }
    setIsLoading(false);
  };

  const ImageHandler = async (ev: any) => {
    const formData = new FormData();
    formData.append("file", ev.target.files[0]);
    formData.append("entity", "resource");
    loader.showLoader();
    if (!ev.target.files[0]) {
      loader.hideLoader();
    }
    const res = await ApiService.postFormData(
      CommonEndpoints.uploadImage(formData)
    ).then((res: any) => {
      setImage(res.data.url);
      loader.hideLoader();
    });
  };

  const showFileUpload = () => {
    fileUpload.current.click();
  };

  const obj = {
    name: {
      required: ValidationService.requiredValidator(),
    },
    phone: {
      required: ValidationService.requiredValidator(),
    },
    email: {},
  };

  const handleChangeBasicInfo = (data: any) => {
    if (!data.name.trim()) {
      toaster.addToast({
        message: "Please enter valid name",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    const request: any = {};
    if (data.name.trim()) {
      request["name"] = data.name.trim();
    }
    if (image !== userDefaultIcon) {
      request["user_image"] = image;
    }
    request["country_code"] = dialCode.current;
    request["phone_number"] = parseInt(data.phone);
    request["otp_code"] = details.otp_code;

    const headers = {
      access_token: appData.loginData.access_token,
      reference_id: referenceId,
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    loader.showLoader();
    return ApiService.put(EditProfileEndpoints.updateProfile(reqData))
      .then((res: any) => {
        loader.hideLoader();
        let loginData = appData.loginData;
        loginData.name = res.data.name;
        loginData.email = res.data.email;
        loginData.access_token = res.data.access_token;
        loginData.user_image = image;
        loginData.phone_number = res.data.phone_number;
        loginData.country_code = res.data.country_code;
        localStorage.setItem("loginData", loginData);
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        let imageforHeader: any = { type: "ProfileImage", value: image };
        headerDropdownService.emit(imageforHeader);
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  const fetchDialCode = (dialcode: any, countrycode: any) => {
    dialCode.current = "+" + dialcode;
    countryCode.current = countrycode.toUpperCase();
  };

  return (
    <div className="EditProfile">
      <div className={classes.pageheading}>{t("Common:Edit Profile")}</div>

      <div className="EditProfile__paper">
        {isLoading ? (
          <div></div>
        ) : (
          <div className={classes.root}>
            <Grid
              item
              xs={12}
              className="editBasicInfo__form__container"
              // style={{ paddingLeft: "28px" }}
            >
              <div className={classes.basicinfo}>
                <form
                  className="changeBasicInfo"
                  onSubmit={handleSubmit(handleChangeBasicInfo)}
                >
                  <Grid container className="editProfile__input__section">
                    <Grid item xs={5} className="input__container">
                      <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginTop: "5px" }}>
                          <Input
                            type="text"
                            id="name"
                            name="name"
                            refValue={register(obj.name)}
                            errors={errors}
                            label={t("Common:Full Name")}
                          ></Input>
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            disabled
                            type="text"
                            id="email"
                            name="email"
                            refValue={register(obj.email)}
                            errors={errors}
                            label={t("Common:Email ID")}
                          ></Input>
                        </Grid>
                        <Grid item xs={12}>
                          <Phone
                            hideLabel={true}
                            showPhone={true}
                            setDialCode={(dialcode: any, countrycode: any) => {
                              fetchDialCode(dialcode, countrycode);
                            }}
                            name="phone"
                            id="phone"
                            label={"Mobile Number"}
                            refValue={register(obj.phone)}
                            placeholder={t("Common:Enter Mobile Number") + "*"}
                            disabled={false}
                            required={true}
                            errors={errors}
                            defaultPhone={preSavedCountryCode}
                          ></Phone>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className="input__container_1">
                      <div className="EditProfile__profile-picture-container">
                        <img
                          src={image}
                          alt="user icon"
                          onMouseEnter={() =>
                            showUploadProfilePictureIcon(true)
                          }
                          onClick={() => {
                            showUploadProfilePictureIcon(true);
                            showFileUpload();
                          }}
                          onMouseLeave={() =>
                            showUploadProfilePictureIcon(false)
                          }
                        ></img>
                        {isShownUploadProfilePictureIcon ? (
                          <div
                            onClick={showFileUpload}
                            onMouseEnter={() =>
                              showUploadProfilePictureIcon(true)
                            }
                            onMouseLeave={() =>
                              showUploadProfilePictureIcon(false)
                            }
                            className="EditProfile__profile-picture-container__camera"
                          >
                            <CameraAltOutlinedIcon color="inherit"></CameraAltOutlinedIcon>
                          </div>
                        ) : (
                          ""
                        )}
                        <input
                          accept="image/*"
                          type="file"
                          className="EditProfile__profile-picture-container__upload-input"
                          onChange={ImageHandler}
                          ref={fileUpload}
                        ></input>
                      </div>
                    </Grid>

                    <Grid
                      container
                      justify="center"
                      className="input__container_2"
                    >
                      <Btn
                        className={classes.btn}
                        type={"Submit"}
                        btnText={t("Common:Submit")}
                      ></Btn>
                      <Btn
                        className={classes.btn}
                        onClick={() => {
                          //clearFields();
                          // getUserProfileDetails(details.email.toString());
                          setPrefillProfile(details);
                          history.push("/jobs/list");
                        }}
                        color={"default"}
                        type={"button"}
                        btnText={t("Common:Cancel")}
                      ></Btn>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>

            {/* <Grid 
              item xs={12} 
            >
              <ChangePassword></ChangePassword>
            </Grid> */}

            {/* <Grid item xs={11}>
              <DeleteAccount></DeleteAccount>
            </Grid> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProfile;
